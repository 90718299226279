.cisco-login {
    &__box {
        border-radius: 4px;
    }

    &__title {
        display: grid;
        place-items: center;
        font-size: large !important;
        padding: 2em;
    }

    &__title-text {
        font-size: 20px !important;
    }

    &__header {
        display: grid;
        place-items: center;
        padding-top: 5em;
    }

    &__logout-button {
        display: grid;
        place-items: center;
    }

    &__container {
        height: 550px;
        display: grid;
        place-items: center;
    }

    &__inner-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__version-text {
        margin-top: auto;
        text-align: center;
        font-size: 14px;
    }

    height: 100vh;
    background-color: white;

    @media screen and (min-width: 500px) {

        &__container {
            display: inherit;
        }

        &__box {
            background-color: #f5f7f8;
            margin: auto;
            width: 600px;
            box-shadow: 0 2px 3px rgb(0, 0, 0), 0 1px 2px rgb(0, 0, 0);
        }


        &__title-text {
            font-size: 25px !important;
        }

        background-color: #0d274d !important;
        display: flex;
        justify-items: center;
    }
}