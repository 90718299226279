body {
  margin: 0;
  font-family: "CiscoSans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7f8;
}

:root {
  --hideDiv: flex;
  --hideRow: table-row;
  --display:none;
  --featurePadding: 9px 9px;
  --stackHeight: 30px;
  --sdaHeight: 114px;
  --table-head-height: undefined;
  --table-row-height: 39px;
}

code {
  font-family: "CiscoSans", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@mixin table-layout {
  box-sizing: border-box;
  height: calc(100vh - 66px);
  margin:10px 0px;
  padding-top:10px;

  &__container{
      margin: 5px 35px ;
      &-body{
          box-sizing: border-box;
          width: 100%;
          overflow: hidden;
          &-header{
              color: #0D274D;
              display: flex;
              justify-content: space-between;
              align-items: center;
          }
          &-title{
              font-size: 1.75rem;
              padding: 10px;
              color: #0D274D;
          }
      }
  }

  &__column-header {
      background-color: #0d274d;
      color: #ffffff;
      .MuiDataGrid-columnSeparator {
          display: none !important;
      }

      .MuiDataGrid-columnHeaderTitle {
          font-size: 15px;
      }
  }

  &__white-background {
      background-color: #ffffff;
  }

  &__table{
      margin-left: 10px;
      margin-top: 10px;
  }

  &__text-ellipsis {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }

  &__loading-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
}

&__loading-bar {
    background-color: #f5f7f8 !important;
}

  .MuiDataGrid-root{
      border-top: none !important;
  }
  
  .MuiDataGrid-columnHeaderWrapper{
      border-radius: 4px 4px 0 0;
  }
  
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
      outline: none;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
      outline: none;
  }

  .MuiDataGrid-row.Mui-odd {
      background-color: #f5f7f8;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
      padding: 0 !important;
  }

  .MuiIconButton-colorSecondary{
      color: #949292;
  }

  .MuiDataGrid-sortIcon{
      color: white !important;
      opacity: none !important;
  }
}

@font-face {
    font-family: "CiscoSans";
    src: local("CiscoSans"),
    url("/assets/fonts/CiscoSansTTRegular.woff") format("woff");
}