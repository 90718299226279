@-moz-document url-prefix() {

  /* scroll color and size */
  * {
    scrollbar-color: #949597 #e2e2e2;
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #949597;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e506d77;
}